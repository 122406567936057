var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"prod-20250320.4"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole, ExtraErrorData } from '@sentry/integrations';
import { config, SENTRY_ALLOWED_URL_REGEX } from './env.config';

console.info('Sentry enabled', config.NEXT_PUBLIC_SENTRY_ENABLED);
console.info('Actual sentry enabled value', process.env.NEXT_PUBLIC_SENTRY_ENABLED);

Sentry.init({
    dsn: config.NEXT_PUBLIC_SENTRY_DSN,
    environment: config.NEXT_PUBLIC_ENVIRONMENT,
    release: config.NEXT_PUBLIC_BUILD_ID,

    // Specifies whether this SDK should send events to Sentry.
    // Setting this to enabled: false doesn't prevent all overhead from Sentry instrumentation.
    // To disable Sentry completely, depending on environment, call Sentry.init conditionally.
    // We still want to experience overhead on DEV and LOCALLY, incase it affects something, so we set it here.
    enabled: config.NEXT_PUBLIC_SENTRY_ENABLED,

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.2,
    allowUrls: [SENTRY_ALLOWED_URL_REGEX],

    integrations: [
        new ExtraErrorData({ depth: 10 }),
        new CaptureConsole({
            levels: ['error'],
        }),
    ],
});
